<i18n src="@/common/locales.json"></i18n>
<template>
  <div class="solid-login">
    <b-button variant="success" v-if="naverUserName == null" @click="login">{{
        $t("login")
      }}</b-button>
    <b-button variant="danger" v-else @click="logout">{{
        $t("logout")
      }}</b-button>
  </div>
</template>

<script>
export default {
  name: "NaverLogin",
  computed: {
    naverUserName() {
      return this.$store.state.naver.naverUserName;
    },
  },
  methods: {
    login() {
      console.log("login");
      this.$router.push("/oauthnaver");
      //this.popupLogin();
    },
    async logout() {
      console.log("logout");
      localStorage.removeItem("naver_access_token")
      this.$router.push("/");
      //await logout();
    },
  },
};
</script>
<style scoped>
.solid-login {
  text-align: center;
}
.solid-login button {
  width: 100%;
  font-weight: 600;
  font-size: 18px;
}
.solid-login button.btn {
}
.solid-login button.btn-secondary {
}
</style>
