import { render, staticRenderFns } from "./NaverLoginButton.vue?vue&type=template&id=49510daa&scoped=true&"
import script from "./NaverLoginButton.vue?vue&type=script&lang=js&"
export * from "./NaverLoginButton.vue?vue&type=script&lang=js&"
import style0 from "./NaverLoginButton.vue?vue&type=style&index=0&id=49510daa&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "49510daa",
  null
  
)

/* custom blocks */
import block0 from "@/common/locales.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fopt%2Fbuild%2Frepo%2Fsrc%2Fcomponents%2Fnaver%2FNaverLoginButton.vue"
if (typeof block0 === 'function') block0(component)

export default component.exports